import React from "react";
import { Link } from "react-router-dom";

const Notfound = () => {
  return (
    <div
      className="fleX w-full h-full g_25"
      style={{ position: "absolute", margin: "auto", flexDirection: "column" }}
    >
      <h1 style={{ margin: "unset" }}>Page Not found</h1>
      <div>The page you are searching for isn't available</div>

      <Link
        to="/home"
        className="bTN flex"
        style={{ textDecoration: "unset", alignItems: "center" }}
      >
        Go to Home
      </Link>
    </div>
  );
};

export default Notfound;
