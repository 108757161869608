import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Spinner from "./Component/Spinner/Spinner.jsx";
import Notfound from "./Component/Notfound.jsx";
import "../src/App.css";
import "./Component/Auth/Style.css";

const Home = lazy(() => import("./modules/landing-Page/landingPage.jsx"));
const AccessManagement = lazy(() => import("./modules/user-Management/userManagement.jsx"));
const Admin = lazy(() => import("./modules/admin/admin.jsx"));

const routes = [
  { path: "/admin", component: Admin },
  { path: ["/additional-info", "/payment", "/contact-us", "/plan", "/blog", "/forgotpassword", "/resetpassword", "/signin", "/signup"], component: AccessManagement },
  { path: ["/solutions", "/home"], component: Home },
];

const App = () => (
  <Router>
    <Suspense fallback={<Spinner />}>
      <Switch>
        {routes.map(({ path, component }, index) => (
          <Route key={index} path={path} component={component} />
        ))}
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route component={Notfound} />
      </Switch>
    </Suspense>
  </Router>
);

export default App;

