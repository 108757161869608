import { configureStore } from '@reduxjs/toolkit';
import dataSlice from './dataSlice';
import Cookies from 'js-cookie';

const saveStateToLocalStorage = (state) => {
  try {
    Cookies.set("reduxAuthToken", JSON.stringify(state));
  } catch (error) {
    console.error('Could not save state', error);
  }
};

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = Cookies.get('reduxAuthToken');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (error) {
    Cookies.remove("reduxAuthToken");
    return undefined;
  }
};

const preloadedState = loadStateFromLocalStorage();

const store = configureStore({
  reducer: {
    counter: dataSlice,
  },
  preloadedState,
});

store.subscribe(() => {
  saveStateToLocalStorage(store.getState());
});

export default store;