import React, { useState, useEffect } from "react";
import "./Spinner.css";

// import { Triangle } from "react-loader-spinner";
// <div
//   className="spin-wrapper"
//   style={{
//     position: "fixed",
//     top: "0",
//     bottom: "0",
//     left: "0",
//     right: "0",
//     margin: "auto",
//   }}
// >
//   <Triangle
//     height="80"
//     width="80"
//     color="#124A99"
//     ariaLabel="triangle-loading"
//     wrapperStyle={{}}
//     wrapperClassName=""
//     visible={true}
//   />
// </div>;

const Spinner = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="fleX fleX_C w-full h_Full fall_Back ">
        <div className="spin_L"></div>
        <div className="spin_C">Acaia</div>
      </div>
    </>
  );
};

export default Spinner;
