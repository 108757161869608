import { createSlice } from '@reduxjs/toolkit';

const todoSlice = createSlice({
    name: 'todos',
    initialState: { todos: [], loading: false, error: null },
    reducers: {
        fetchTodosStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchTodosSuccess: (state, action) => {
            state.loading = false;
            state.todos = action.payload;
        },
        fetchTodosFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchTodosStart, fetchTodosSuccess, fetchTodosFailure } = todoSlice.actions;
export default todoSlice.reducer;
